@keyframes snow {
    0% {
      transform: translateY(-100%);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh);
      opacity: 0.5;
    }
  }
  
  .animate-snow {
    animation-name: snow;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  