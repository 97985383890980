/* src/components/Loader.css */

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con transparencia */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que esté por encima de otros elementos */
  }
  
  .loader {
    position: relative; /* Para posicionar el ícono en el centro */
    width: 50px; /* Tamaño del círculo */
    height: 50px; /* Tamaño del círculo */
    border: 2px solid rgba(241, 30, 30, 0.3);
    border-top: 8px solid #fff; /* Color del spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Animación de giro */
  }
  
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centrar el ícono */
    color: white; /* Color del ícono */
    font-size: 24px; /* Tamaño del ícono */
    animation: spin 1s linear infinite; /* Animación de giro para el ícono */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  