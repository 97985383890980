@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  font-family: "Poppins", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  overflow-x: hidden;
}

.responsive-link-polity {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1.25rem;
}

/* Media query para pantallas de 480px o menos */
@media (max-width: 480px) {
  .responsive-link-polity {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.container-boton {
  background-color: #2e6329;
  border: 1px solid #fff;
  position: fixed;
  z-index: 999;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  padding: 5px;
  transition: opacity 0.5s ease-in-out;
  animation: efecto 1.2s infinite;
  opacity: 0;
}

.container-boton:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.boton {
  width: 2.5rem;
}

.mostrar {
  opacity: 1;
}

@keyframes efecto {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.85);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.texto-emergente {
  position: absolute;
  top: 50%;
  right: -200px;
  transform: translateY(-50%);
  opacity: 0;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.container-boton:hover .texto-emergente {
  right: 60px;
  opacity: 1;
}

footer {
  /*background: linear-gradient(to right, rgb(255, 100, 100), rgb(100, 100, 255)); /* Degradado de rojo a azul */
  /*background: linear-gradient(to right, rgb(0, 0, 0), rgb(0, 0, 255), rgb(255, 255, 255, 0.1)); /* Degradado de negro a azul con un poco de blanco */
  background: linear-gradient(
    to right,
    rgb(29, 28, 28),
    rgba(153, 27, 27, 0.74),
    rgba(255, 255, 255, 0.1)
  );
  background-image: url('/public/images/fondo-01.jpeg'); /* Asegúrate de que la ruta sea correcta */
  background-size: cover; /* Ajusta la imagen para que cubra todo el footer */
  background-position: center; /* Centra la imagen */
  position: relative; /* Necesario para el pseudo-elemento */
}

footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Color negro semi-transparente para dar opacidad */
  z-index: 1; /* Asegura que el overlay esté encima de la imagen */
}

footer > * {
  position: relative; /* Asegura que el contenido del footer esté encima del overlay */
  z-index: 2; /* Hace que el contenido esté por encima del overlay */
}

.px-fecha-ES {
  padding-left: 1.5rem /* 24px */;
  padding-right: 1.5rem /* 24px */;
}

@media (max-width: 440px) {
  .px-fecha-ES {
    padding-left: 0.05rem /* 24px */;
    padding-right: 0.05rem /* 24px */;
  }
}


/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b4b4b4;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
}

/* Agregar al archivo de estilos global */
