.slider-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden; /* Ocultar cualquier desbordamiento */
  }
  
  .slider-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Transiciones suaves para la transformación y opacidad */
    opacity: 0; /* Inicialmente invisible */
  }
  
  .slider-slide.active {
    opacity: 1; /* Visible solo cuando es la imagen activa */
    transform: translateX(0); /* Mantener la posición al ser la activa */
  }
  
  .slider-slide.translate-right {
    transform: translateX(100%); /* Desplazamiento a la derecha */
  }
  
  .slider-slide.translate-left {
    transform: translateX(-100%); /* Desplazamiento a la izquierda */
  }
  
  .slider-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slider-content {
    text-align: center;
    color: white;
    padding: 20px;
  }
  
  .slider-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  
  .slider-indicator {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background: gray;
    cursor: pointer;
  }
  
  .indicator-active {
    background: white; /* Color para el indicador activo */
  }
  
  .indicator-inactive {
    background: gray; /* Color para los indicadores inactivos */
  }
  